import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  RemainingImagesText,
  RemainingImagesWrapper,
  MainWrapper,
  Overlay,
} from './ContainerImageList.css';
import { ContainerImageListProps } from './ContainerImageList.types';
import ContainerImageCard from './ContainerImageCard/ContainerImageCard';
import React from 'react';
import { trackClickEvent } from '@frontend/tracking';

export default function ContainerImageList({
  containerId,
  imageList,
  openInLightbox,
}: ContainerImageListProps) {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const [isImagesExpanded, setIsImagesExpanded] = useState<boolean>(false);
  const MAX_IMAGES_NUMBER = isMobile ? 4 : 5;

  const remainingImages = intl.formatMessage(
    {
      id: 'customer-platform.request-details.order-list.container-drawer.images.remaining',
    },
    { REMAINING_FILES: (imageList.length - MAX_IMAGES_NUMBER).toString() },
  );

  const handleClickOnImage = (index: number, imageName: string) => {
    if (openInLightbox) {
      openInLightbox(index);
      trackClickEvent({
        elementId: `container-drawer.image-list.${imageName}`,
      });
    }
  };
  return (
    <MainWrapper data-testid={'container-image-list'}>
      {imageList.map((image, index) =>
        isImagesExpanded || index < MAX_IMAGES_NUMBER ? (
          <React.Fragment key={image.uuid}>
            {index === MAX_IMAGES_NUMBER - 1 ? (
              <RemainingImagesWrapper onClick={() => setIsImagesExpanded(true)}>
                <ContainerImageCard
                  key={image.uuid}
                  containerId={containerId}
                  imageId={image.uuid}
                  onClick={() =>
                    isImagesExpanded &&
                    handleClickOnImage(MAX_IMAGES_NUMBER - 1, image.name)
                  }
                />
                {!isImagesExpanded && (
                  <Overlay>
                    <RemainingImagesText>
                      <Typography variant="caption1" component="span">
                        {remainingImages}
                      </Typography>
                    </RemainingImagesText>
                  </Overlay>
                )}
              </RemainingImagesWrapper>
            ) : (
              <ContainerImageCard
                key={image.uuid}
                containerId={containerId}
                imageId={image.uuid}
                onClick={() => handleClickOnImage(index, image.name)}
              />
            )}
          </React.Fragment>
        ) : null,
      )}
    </MainWrapper>
  );
}
