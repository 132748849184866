import {
  IListingSubmissionData,
  IMaterialPhotos,
  ISeller,
  ISellerRegistrationListing,
  useCreateSellerAndListing,
} from '@frontend/api';
import { LOGIN_LINK_SOURCE, useAuth } from '@frontend/auth';
import { generateUrlFromLocation } from '@frontend/routing';
import { trackClickEvent } from '@frontend/tracking';
import { Translate } from '@frontend/translation';
import { Button, InnerTypography } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import { identity, pickBy } from 'lodash';
import { useEffect, useRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import {
  BackgroundWrapper,
  FakeSeparator,
  HeadingWrapper,
  ImageWrapper,
  LayoutWrapper,
  LeftWrapper,
  RightWrapper,
  SubheadingBox,
} from './SellMetal.css';
import { RE_LOGIN_SOURCE } from '../LoginValidation/LoginValidation.const';
import FormType from '../../app/shared/components/Forms/utils/FormType.types';
import ListingForm from '../../app/shared/components/Forms/ListingForm/ListingForm';
import LoginLinkExpiredModal from '../../app/shared/components/Modals/LoginLinkExpiredModal/LoginLinkExpiredModal';
import LoginModal from '../../app/shared/components/Modals/LoginModal/LoginModal';
import PlatformHeader from '../../app/shared/components/PlatformHeader/PlatformHeader';
import SellerDashboardImage from '/assets/images/seller_dashboard.png';
import SellerForm from './SellerForm/SellerForm';
import StepsWizard from '../../app/shared/components/StepsWizard/StepsWizard';
import SuccessModal from '../../app/shared/components/Modals/SuccessModal/SuccessModal';
import useModal from '../../app/shared/components/Modals/useModal/useModal';
import useStepsWizard from '../../app/shared/components/StepsWizard/useStepsWizard';
import { HEADER_VARIANTS } from '../../app/shared/components/PlatformHeader/PlatformHeader.types';

export function SellMetal() {
  const intl = useIntl();
  const location = useLocation();
  const theme = useTheme();
  const { checkSession } = useAuth();

  const {
    openLoginModal,
    openLinkExpiredModal,
    openLinkLoginModal,
    openSuccessModal,
    closeModal,
    isLoginModalOpen,
    isSuccessModalOpen,
    isLinkExpiredModalOpen,
    isLinkLoginModalOpen,
  } = useModal();

  const { currentStep, goToNextStep, goToPreviousStep } = useStepsWizard();

  const formData = useRef<ISellerRegistrationListing>({
    user: {},
    listing: {},
    materialPhotos: [],
  });
  const { mutate, status, error } = useCreateSellerAndListing();

  const handleListingFormSubmit: SubmitHandler<
    FormType<IListingSubmissionData>
  > = listingData => {
    const { materialPhotos = [], ...restListingData } = listingData;

    formData.current.listing = pickBy(restListingData, identity);
    formData.current.materialPhotos = materialPhotos as IMaterialPhotos;
    goToNextStep();
  };

  const handleSellerFormSubmit: SubmitHandler<FormType<ISeller>> = userData => {
    userData.phoneNumber = userData.phoneNumber?.replace(/ /g, '');
    formData.current.user = pickBy(userData, identity);

    mutate(formData.current);
    if (error?.response?.data?.listing) {
      goToPreviousStep();
    }
  };

  const OnboardingImage = () => {
    const imageId = 'sell-metal.visual-onboarding.image';
    const imageAltText = intl.formatMessage({
      id: 'customer-platform.sell-metal.visual-onboarding.image-alt',
    });

    return (
      <ImageWrapper
        id={imageId}
        onClick={() =>
          trackClickEvent({
            elementId: imageId,
            elementText: imageAltText,
          })
        }
      >
        <img
          srcSet={`${SellerDashboardImage}?dpr=2 2x`}
          src={SellerDashboardImage}
          alt={imageAltText}
          loading="lazy"
        />
      </ImageWrapper>
    );
  };

  useEffect(() => {
    const queryParamsSource = new URLSearchParams(location.search).get(
      'source',
    );
    if (queryParamsSource === RE_LOGIN_SOURCE) {
      openLinkExpiredModal();
    } else if (queryParamsSource === LOGIN_LINK_SOURCE) {
      openLinkLoginModal();
    }
  }, [location, openLinkExpiredModal, openLinkLoginModal]);

  useEffect(() => {
    if (status === 'success') {
      openSuccessModal();
    }
  }, [openSuccessModal, status]);

  return (
    <BackgroundWrapper>
      <PlatformHeader variant={HEADER_VARIANTS.GUEST} />
      <LayoutWrapper>
        <LeftWrapper>
          <HeadingWrapper>
            <Typography component="h1" variant="h2">
              <InnerTypography
                message={intl.formatMessage({
                  id: 'customer-platform.sell-metal.listing-form.title',
                })}
                variant="h2"
                color={theme.palette.primary[500]}
              />
            </Typography>

            <SubheadingBox>
              <Typography variant="p3">
                <Translate id="customer-platform.sell-metal.listing-form.subtitle.text" />
              </Typography>
              <Button
                buttonType="linked"
                id="sell-metal.button.open-login"
                size="large"
                onClick={openLoginModal}
              >
                <Translate id="customer-platform.sell-metal.listing-form.subtitle.link" />
              </Button>
              {(isLoginModalOpen || isLinkLoginModalOpen) && (
                <LoginModal
                  isLoginLink={isLinkLoginModalOpen}
                  isOpen={true}
                  onClose={closeModal}
                  sourceUrl={location.pathname}
                  redirectUrl={
                    location.state?.from
                      ? generateUrlFromLocation(location.state.from)
                      : undefined
                  }
                />
              )}
              {isLinkExpiredModalOpen && (
                <LoginLinkExpiredModal
                  onClose={closeModal}
                  onClickOpenLoginModal={openLoginModal}
                />
              )}
            </SubheadingBox>
          </HeadingWrapper>

          <StepsWizard
            currentStep={currentStep}
            labels={[
              'customer-platform.sell-metal.listing-form.steps-wizard.listing-step.label',
              'customer-platform.sell-metal.listing-form.steps-wizard.seller-step.label',
            ]}
          >
            <ListingForm
              errors={error?.response?.data?.listing}
              status={status}
              onSubmit={handleListingFormSubmit}
              submitTranslationId={
                'customer-platform.sell-metal.listing-form.tabs.listing-tab.button'
              }
            />
            <SellerForm
              errors={error?.response?.data?.user}
              status={status}
              onPrevStepClick={goToPreviousStep}
              onSubmit={handleSellerFormSubmit}
            />
          </StepsWizard>

          {isSuccessModalOpen && (
            <SuccessModal
              title={intl.formatMessage({
                id: 'customer-platform.sell-metal.listing-form.success-modal.title',
              })}
              text={intl.formatMessage({
                id: 'customer-platform.sell-metal.listing-form.success-modal.text',
              })}
              primaryCta={{
                text: intl.formatMessage({
                  id: 'customer-platform.sell-metal.listing-form.success-modal.cta.primary',
                }),
                onClick: () => {
                  if (checkSession) checkSession();
                },
              }}
              secondaryCta={{
                text: intl.formatMessage({
                  id: 'customer-platform.sell-metal.listing-form.success-modal.cta.secondary',
                }),
                onClick: () => {
                  window.location.href = import.meta.env.VITE_STATIC_APP_URL;
                },
              }}
            />
          )}
        </LeftWrapper>
        <FakeSeparator />
        <RightWrapper>
          <OnboardingImage />
          <HeadingWrapper>
            <Typography
              component="h3"
              variant="h2"
              color={theme.palette.primary[500]}
            >
              <Translate id="customer-platform.sell-metal.visual-onboarding.title" />
            </Typography>
            <Typography variant="p1" color={theme.palette.primary[500]}>
              <Translate id="customer-platform.sell-metal.visual-onboarding.paragraph" />
            </Typography>
          </HeadingWrapper>
        </RightWrapper>
      </LayoutWrapper>
    </BackgroundWrapper>
  );
}

export default SellMetal;
