import { FormFieldAction, trackFormFieldEvent } from '@frontend/tracking';
import { Typography, useTheme } from '@mui/material';
import { MouseEvent } from 'react';
import {
  CheckboxInput,
  CheckBoxLabel,
  CheckBoxWrapper,
  HelperTextWrapper,
} from './CheckBox.css';
import CheckBoxProps from './CheckBox.types';
import CheckboxBlankLineIcon from 'remixicon-react/CheckboxBlankLineIcon';
import CheckboxFillIcon from 'remixicon-react/CheckboxFillIcon';

const CheckBox = ({
  label,
  error,
  helperText,
  formId,
  required,
  value,
  onClick,
  ...rest
}: CheckBoxProps) => {
  const theme = useTheme();

  const trackFieldEvent = (
    action: FormFieldAction,
    oldValue?: boolean,
    newValue?: boolean,
  ) => {
    trackFormFieldEvent({
      formId: formId ?? 'unknown-form',
      formField: rest.id ?? 'unknown-field',
      formFieldAction: action,
      formFieldOldValue: oldValue ? 'checked' : 'unchecked',
      formFieldNewValue: newValue ? 'checked' : 'unchecked',
    });
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    trackFieldEvent('update', value, !value);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <CheckBoxWrapper>
      <CheckBoxLabel
        label={
          <Typography variant="p1">
            {label}
            {required ? ' *' : ''}
          </Typography>
        }
        control={
          <CheckboxInput
            icon={
              <CheckboxBlankLineIcon
                color={
                  error ? theme.palette.error[500] : theme.palette.neutral[200]
                }
              />
            }
            checkedIcon={
              <CheckboxFillIcon
                color={
                  error ? theme.palette.error[500] : theme.palette.primary[500]
                }
              />
            }
            onClick={handleClick}
            checked={value}
            value={value}
            {...rest}
          />
        }
      />
      {helperText && (
        <HelperTextWrapper>
          <Typography variant="p3" color={theme.palette.error[500]}>
            {helperText}
          </Typography>
        </HelperTextWrapper>
      )}
    </CheckBoxWrapper>
  );
};

export default CheckBox;
