import { includes } from 'lodash';
import { useIntl } from 'react-intl';
import {
  useGetContainerImage,
  SUPPORTED_MIME_IMAGE_FORMATS,
} from '@frontend/api';
import { CircularProgress } from '@mui/material';
import { ImageWrapper, LoadingWrapper } from './ContainerImageCard.css';
import { ContainerImageCardProps } from './ContainerImageCard.types';
import PlaceholderImage from '../../PlaceholderImage/PlaceholderImage';

export default function ContainerImageCard({
  containerId,
  imageId,
  onClick,
}: ContainerImageCardProps) {
  const intl = useIntl();
  const { status, data } = useGetContainerImage(containerId, imageId);

  return (
    <ImageWrapper>
      {status === 'success' &&
        (includes(SUPPORTED_MIME_IMAGE_FORMATS, data.data?.type) ? (
          <img
            src={URL.createObjectURL(data.data)}
            alt={intl.formatMessage({
              id: 'customer-platform.request-details.order-list.container-drawer.images.image.alt-text',
            })}
            loading="lazy"
            onClick={onClick}
          />
        ) : (
          <PlaceholderImage
            icon={'hourglass'}
            caption={intl.formatMessage({
              id: 'customer-platform.request-details.order-list.container-drawer.container-photos.placeholder-image-loading',
            })}
            onClick={onClick}
          />
        ))}
      {status === 'pending' && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}
    </ImageWrapper>
  );
}
