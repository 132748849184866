import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';
export const ImageWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flex: '1 1 calc(100%/5 - 12px)',
  maxWidth: 'calc(100%/5 - 12px)',
  [theme.breakpoints.down('tablet')]: {
    flex: '1 1 calc(100%/4 - 12px)',
    maxWidth: 'calc(100%/4 - 12px)',
  },
  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
  },
}));

export const LoadingWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));
