import { styled } from '@frontend/theme';
import { Box as MUIBox, Container as MUIContainer } from '@mui/material';

export const ContentWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  width: '100%',
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
    gap: '36px',
  },
}));

export const MessageWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
}));

export const LogoWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  '& > svg': {
    width: '350px',
    height: '70px',
  },
}));

export const PageContainer = styled(MUIContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
  gap: '70px',
  maxWidth: theme.breakpoints.values.desktopL,
  padding: '150px 120px 152px 64px',
  [theme.breakpoints.down('desktop')]: {
    padding: '100px 64px 120px 64px',
  },
  [theme.breakpoints.down('tabletL')]: {
    gap: '36px',
    maxWidth: theme.breakpoints.values.tabletL,
    padding: '60px 116px',
  },
  [theme.breakpoints.down('tablet')]: {
    gap: '24px',
    maxWidth: theme.breakpoints.values.tablet,
    padding: '40px 20px',
  },
}));
