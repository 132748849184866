import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import { IGetListingImageResponseFailure } from './getListingImage.types';

const submitGetListingImage = (
  apiUrl: string,
  listingId: string,
  imageId: string,
) => {
  return axiosClient.get(
    `${apiUrl}/trading/listings/${listingId}/photos/${imageId}/download/`,
    {
      responseType: 'blob',
    },
  );
};

const useGetListingImageQuery = (listingId: string, imageId: string) => {
  const { apiUrl } = useContext(ApiContext);
  const queryKey = ['listing', 'image', listingId, imageId];

  return useQuery<
    AxiosResponse<Blob>,
    AxiosError<IGetListingImageResponseFailure>,
    AxiosResponse<Blob>
  >({
    queryFn: () => submitGetListingImage(apiUrl, listingId, imageId),
    queryKey,
  });
};

export default useGetListingImageQuery;
