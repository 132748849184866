import { MainContainer } from './EmptyDashboard.css';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Translate } from '@frontend/translation';

export default function EmptyDashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <MainContainer>
      <Typography variant={isMobile ? 'h4' : 'h2'}>
        <Translate id="customer-platform.request-list.empty.heading" />
      </Typography>
      <Typography variant={isMobile ? 'h5' : 'h4'}>
        <Translate id="customer-platform.request-list.empty.subtitle" />
      </Typography>
    </MainContainer>
  );
}
