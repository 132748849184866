import { styled, theme } from '@frontend/theme';
import { Container as MUIContainer, Box as MUIBox } from '@mui/material';
import { IHeaderWrapper } from './OrderOverview.types';

export const MainContainer = styled(MUIContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 12px 0 0',
  gap: '36px',
  '&:last-of-type': {
    paddingBottom: '36px',
  },
  [theme.breakpoints.down('tablet')]: {
    gap: '24px',
    padding: '0',
    '&:last-of-type': {
      paddingBottom: '0',
    },
  },
}));

export const HeaderWrapper = styled(MUIContainer, {
  shouldForwardProp: prop => prop !== 'selected',
})(({ selected }: IHeaderWrapper) => ({
  display: 'flex',
  gap: '20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: selected ? 'column' : 'row',
    alignItems: selected ? 'flex-start' : 'center',
  },
}));

export const ButtonAndTextWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '20px',
}));
