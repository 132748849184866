import { Shadows } from '@mui/material';

// We only have six shadows rest are same
export const shadows: Shadows = [
  'none',
  '0px 0px 0px 0px rgba(0, 0, 0, 0.00);',
  '0px 2px 4px 0px rgba(36, 36, 36, 0.06);',
  '0px 2px 8px 0px rgba(36, 36, 36, 0.08);',
  '0px 4px 12px 0px rgba(36, 36, 36, 0.08);',
  '0px 6px 24px 0px rgba(36, 36, 36, 0.10);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
  '2px 8px 48px 0px rgba(36, 36, 36, 0.20);',
];
