import { RemixiconReactIconComponentType } from 'remixicon-react';
import { IconWrapper } from './Icon.css';
import { IconProps } from './Icon.types';
import CheckDoubleFillIcon from 'remixicon-react/CheckDoubleFillIcon';
import FundsBoxLineIcon from 'remixicon-react/FundsBoxLineIcon';
import MailSendLineIcon from 'remixicon-react/MailSendLineIcon';
import PdfIcon from './assets/PdfIcon';
import StockFillIcon from 'remixicon-react/StockFillIcon';
import TreasureMapLineIcon from 'remixicon-react/TreasureMapLineIcon';
import UserAddLineIcon from 'remixicon-react/UserAddLineIcon';

export default function Icon({ color, size = 20, variant }: IconProps) {
  const ICON_MAPPING = {
    'user-add': UserAddLineIcon,
    'check-double': CheckDoubleFillIcon,
    'mail-send': MailSendLineIcon,
    'funds-box': FundsBoxLineIcon,
    stock: StockFillIcon,
    'treasure-map': TreasureMapLineIcon,
    pdf: PdfIcon,
  } as Record<string, RemixiconReactIconComponentType>;

  const Icon = ICON_MAPPING[variant];

  if (Icon) {
    return variant === 'pdf' ? (
      <IconWrapper color={color} size={size}>
        <Icon />
      </IconWrapper>
    ) : (
      <Icon color={color} size={size} />
    );
  }

  return null;
}
