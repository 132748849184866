import ImageLineIcon from 'remixicon-react/ImageLineIcon';
import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import { MainWrapper } from './EmptyContainerImageList.css';

export default function EmptyContainerImageList() {
  return (
    <MainWrapper>
      <ImageLineIcon size={24} />
      <Typography variant="p3">
        <Translate id="customer-platform.request-details.order-list.container-drawer.images.empty" />
      </Typography>
    </MainWrapper>
  );
}
