import { useNavigate, useParams } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { Translate } from '@frontend/translation';
import { Button } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  BannerWrapper,
  ButtonWrapper,
  BuyerDashboardHeaderWrapper,
  RequestCounter,
  RequestCounterWrapper,
  Separator,
} from './BuyerDashboardHeader.css';
import { BuyerDashboardHeaderProps } from './BuyerDashboardHeader.types';
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';
import CompanyStatusBanner from './CompanyStatusBanner/CompanyStatusBanner';
import EmailVerificationBanner from '../../../app/shared/components/EmailVerificationBanner/EmailVerificationBanner';

export default function BuyerDashboardHeader({
  requestsCount,
}: BuyerDashboardHeaderProps) {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('desktop'));
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const showCounter =
    requestsCount !== undefined && (orderId === undefined || !isTablet);
  const showHeader = !(isMobile && orderId !== undefined);

  const handleClick = () => {
    navigate(PLATFORM_ROUTES.buyerDashboard);
  };

  return (
    showHeader && (
      <BuyerDashboardHeaderWrapper isRequestsView={showCounter}>
        <Typography variant="h2">
          <Translate id="customer-platform.request-list.heading" />
        </Typography>
        <BannerWrapper>
          <EmailVerificationBanner />
          <CompanyStatusBanner />
        </BannerWrapper>
        {showCounter ? (
          <RequestCounterWrapper>
            <RequestCounter>
              <Typography variant="p2">
                <Translate id="customer-platform.request-list.number-of-requests" />
              </Typography>
              <Typography variant="p4">{requestsCount}</Typography>
            </RequestCounter>
            <Separator />
          </RequestCounterWrapper>
        ) : (
          <ButtonWrapper>
            <Button
              buttonType="linked"
              id="request-details-header.button.go-back"
              linkedButtonColorValue="black"
              startIcon={<ArrowLeftLineIcon size={24} />}
              size="large"
              onClick={handleClick}
            >
              <Translate id="customer-platform.request-details.header.previous-page-cta" />
            </Button>
          </ButtonWrapper>
        )}
      </BuyerDashboardHeaderWrapper>
    )
  );
}
