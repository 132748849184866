export const METAL_TYPE = [
  'metal.type.aluminium',
  'metal.type.mixed_metals',
  'metal.type.zinc',
  'metal.type.magnesium',
  'metal.type.lead',
  'metal.type.nickel/stainless/hi-temp',
  'metal.type.copper',
  'metal.type.brass',
  'metal.type.steel',
  'metal.type.iron',
] as const;
export type TMETAL_TYPE = (typeof METAL_TYPE)[number];

export const ISRI_GRADE = [
  'isri_grade.not_applicable',
  'isri_grade.aroma',
  'isri_grade.barley',
  'isri_grade.berry',
  'isri_grade.berry_candy',
  'isri_grade.birch',
  'isri_grade.birch_cliff',
  'isri_grade.burly',
  'isri_grade.candy',
  'isri_grade.cliff',
  'isri_grade.clove',
  'isri_grade.cobra',
  'isri_grade.cocoa',
  'isri_grade.dandy',
  'isri_grade.darth',
  'isri_grade.daunt',
  'isri_grade.decoy',
  'isri_grade.delta',
  'isri_grade.depth',
  'isri_grade.dream',
  'isri_grade.drink',
  'isri_grade.droid',
  'isri_grade.drove',
  'isri_grade.druid',
  'isri_grade.ebony',
  'isri_grade.ebulent',
  'isri_grade.ecstatic',
  'isri_grade.eland',
  'isri_grade.elder',
  'isri_grade.elias',
  'isri_grade.elmo',
  'isri_grade.enerv',
  'isri_grade.engel',
  'isri_grade.erin',
  'isri_grade.fence',
  'isri_grade.ferry',
  'isri_grade.grape',
  'isri_grade.hitch',
  'isri_grade.honey',
  'isri_grade.house',
  'isri_grade.ideal',
  'isri_grade.indian',
  'isri_grade.ingots',
  'isri_grade.ivory',
  'isri_grade.junto',
  'isri_grade.label',
  'isri_grade.lace',
  'isri_grade.lady',
  'isri_grade.lake',
  'isri_grade.lamb',
  'isri_grade.lark',
  'isri_grade.lemon',
  'isri_grade.lemur',
  'isri_grade.maize',
  'isri_grade.major',
  'isri_grade.malar',
  'isri_grade.malic',
  'isri_grade.melon',
  'isri_grade.naggy',
  'isri_grade.nascent',
  'isri_grade.niche',
  'isri_grade.niece',
  'isri_grade.night',
  'isri_grade.noble',
  'isri_grade.nomad',
  'isri_grade.ocean',
  'isri_grade.pales',
  'isri_grade.pallu',
  'isri_grade.palms',
  'isri_grade.parch',
  'isri_grade.pekoe',
  'isri_grade.racks',
  'isri_grade.radio',
  'isri_grade.rains',
  'isri_grade.rakes',
  'isri_grade.ranch',
  'isri_grade.ranks',
  'isri_grade.raves',
  'isri_grade.reels',
  'isri_grade.relay',
  'isri_grade.rents',
  'isri_grade.rink',
  'isri_grade.rono',
  'isri_grade.roper',
  'isri_grade.ropes',
  'isri_grade.roses',
  'isri_grade.sabot',
  'isri_grade.saint',
  'isri_grade.saves',
  'isri_grade.scabs',
  'isri_grade.scoot',
  'isri_grade.scope',
  'isri_grade.score',
  'isri_grade.screen',
  'isri_grade.scribe',
  'isri_grade.scroll',
  'isri_grade.scrub',
  'isri_grade.scull',
  'isri_grade.seal',
  'isri_grade.seam',
  'isri_grade.sheema',
  'isri_grade.shelf',
  'isri_grade.shelmo',
  'isri_grade.small-elmo',
  'isri_grade.tablet',
  'isri_grade.tabloid',
  'isri_grade.taboo',
  'isri_grade.taint_tabor',
  'isri_grade.take',
  'isri_grade.talc',
  'isri_grade.talcred',
  'isri_grade.taldack',
  'isri_grade.taldon',
  'isri_grade.taldork',
  'isri_grade.tale',
  'isri_grade.talk',
  'isri_grade.tall',
  'isri_grade.tally',
  'isri_grade.talon',
  'isri_grade.tank',
  'isri_grade.tann',
  'isri_grade.tarry_a',
  'isri_grade.tarry_b',
  'isri_grade.tarry_c',
  'isri_grade.tassel',
  'isri_grade.taste',
  'isri_grade.tata',
  'isri_grade.tease',
  'isri_grade.telic',
  'isri_grade.tense',
  'isri_grade.tepid',
  'isri_grade.terse',
  'isri_grade.tesla',
  'isri_grade.tetra',
  'isri_grade.thigh',
  'isri_grade.thirl',
  'isri_grade.throb',
  'isri_grade.thron',
  'isri_grade.tooth',
  'isri_grade.toto',
  'isri_grade.tough',
  'isri_grade.tread',
  'isri_grade.trill',
  'isri_grade.troma',
  'isri_grade.trump',
  'isri_grade.tutu',
  'isri_grade.twang',
  'isri_grade.tweak',
  'isri_grade.twire',
  'isri_grade.twirl',
  'isri_grade.twist',
  'isri_grade.twitch',
  'isri_grade.ultra',
  'isri_grade.vader',
  'isri_grade.vaunt',
  'isri_grade.wafer',
  'isri_grade.walnut',
  'isri_grade.wine',
  'isri_grade.wood',
  'isri_grade.world',
  'isri_grade.zebra',
  'isri_grade.zeppelin',
  'isri_grade.zeyda',
  'isri_grade.zorba',
  'isri_grade.zurik',
] as const;
export type TISRI_GRADE = (typeof ISRI_GRADE)[number];

export const DESTINATION_ADDRESS_INCOTERMS = [
  'incoterms.dap',
  'incoterms.ddp',
  'incoterms.dpu',
  'incoterms.cpt',
  'incoterms.cip',
];
export const SHIPPING_PORT_INCOTERMS = [
  'incoterms.exw',
  'incoterms.cif',
  'incoterms.fca',
  'incoterms.fas',
  'incoterms.fob',
  'incoterms.cfr',
];
export const INCOTERMS = [
  ...DESTINATION_ADDRESS_INCOTERMS,
  ...SHIPPING_PORT_INCOTERMS,
] as const;
export type TINCOTERMS = (typeof INCOTERMS)[number];

export const METAL_FORM = [
  'metal.form.jumbo_bag',
  'metal.form.baled',
  'metal.form.loose',
  'metal.form.not_specified',
] as const;
