import * as contentful from 'contentful';

// This using default is required because vite and rollup are compiling the package differently
// https://github.com/contentful/contentful.js/issues/1233#issuecomment-1456369601

const client = contentful.createClient
  ? contentful.createClient({
      space: 'b482wvamo5jo',
      accessToken: '3jwqbkQ3wY40_m4K8XT5AGja-jB_gxcNrWqNC71Ou5w',
    })
  : // @ts-ignore
    (contentful.default.createClient({
      space: 'b482wvamo5jo',
      accessToken: '3jwqbkQ3wY40_m4K8XT5AGja-jB_gxcNrWqNC71Ou5w',
    }) as contentful.ContentfulClientApi<undefined>);

export default client;
