import { styled } from '@frontend/theme';
import { Container as MUIContainer } from '@mui/material';

export const MainContainer = styled(MUIContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '24px',
}));
