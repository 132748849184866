import { useState } from 'react';
import {
  SUPPORTED_IMAGE_FORMATS,
  useGetContainerImageURL,
} from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Typography } from '@mui/material';
import { ContainerImagesProps } from './ContainerImages.types';
import { ContainerImagesWrapper } from './ContainerImages.css';
import ContainerImageList from './ContainerImageList/ContainerImageList';
import DownloadAllContainerImages from './DownloadAllContainerImages/DownloadAllContainerImages';
import EmptyContainerImageList from './EmptyContainerImageList/EmptyContainerImageList';
import ContainerPDFImagesList from './ContainerImageList/ContainerPDFImagesList/ContainerPDFImagesList';
import { SUPPORTED_IMAGE_DOCUMENT_FORMATS } from '@frontend/api';
import ImagesLightbox from '../../../../../../../../../../app/shared/components/ImagesLightbox/ImagesLightbox';
import { isFileTypeIncluded } from '../../../../../../../../../../app/shared/utils/fileType.utils';

export default function ContainerImages({
  containerId,
  imageList,
  containerNumber,
}: ContainerImagesProps) {
  const acceptedImageList = imageList.filter(
    image => !isFileTypeIncluded(image.type, SUPPORTED_IMAGE_DOCUMENT_FORMATS),
  );

  const { getContainerImageURL } = useGetContainerImageURL();

  const pdfImagesList = imageList.filter(image =>
    isFileTypeIncluded(image.type, SUPPORTED_IMAGE_DOCUMENT_FORMATS),
  );

  const [lightboxActive, setLightboxActive] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openLightbox = (clickedImageIndex: number) => {
    setSelectedImageIndex(clickedImageIndex);
    setLightboxActive(true);
  };

  return (
    <ContainerImagesWrapper>
      <Typography variant="h5">
        <Translate id="customer-platform.request-details.order-list.container-drawer.images" />
      </Typography>
      {acceptedImageList.length ? (
        <>
          <ContainerImageList
            containerId={containerId}
            imageList={acceptedImageList}
            openInLightbox={openLightbox}
          />

          {pdfImagesList.length > 0 && (
            <ContainerPDFImagesList
              containerId={containerId}
              imageList={pdfImagesList}
            />
          )}

          <ImagesLightbox
            slidesList={acceptedImageList.map(containerImage => {
              return {
                uuid: containerImage.uuid,
                slideType: isFileTypeIncluded(
                  containerImage.type,
                  SUPPORTED_IMAGE_FORMATS,
                )
                  ? 'image'
                  : 'placeholder',
              };
            })}
            isOpen={lightboxActive}
            closeLightbox={() => setLightboxActive(false)}
            imageIndex={selectedImageIndex}
            getImageURLFromId={elementId =>
              getContainerImageURL(containerId, elementId)
            }
          />

          <DownloadAllContainerImages
            containerId={containerId}
            imageList={imageList}
            containerNumber={containerNumber}
          />
        </>
      ) : (
        <EmptyContainerImageList />
      )}
    </ContainerImagesWrapper>
  );
}
