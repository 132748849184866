import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IGetImageResponseFailure } from './getImage.types';
import axiosClient from '../axiosClient';
import { useGetContainerImageURL } from './getImageURL';

const useGetContainerImageQuery = (containerId: string, imageId: string) => {
  const QueryKey = ['image', containerId, imageId];
  const { getContainerImageURL } = useGetContainerImageURL();

  const submitGetContainerImage = (imageUrl: string) => {
    return axiosClient.get(imageUrl, { responseType: 'blob' });
  };

  const imageUrl = getContainerImageURL(containerId, imageId);

  return useQuery<
    AxiosResponse<Blob>,
    AxiosError<IGetImageResponseFailure>,
    AxiosResponse<Blob>
  >({
    queryFn: () => submitGetContainerImage(imageUrl),
    queryKey: QueryKey,
  });
};

export default useGetContainerImageQuery;
