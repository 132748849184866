import {
  LOGIN_LINK_SOURCE,
  RE_VERIFICATION_SOURCE,
  useAuth,
} from '@frontend/auth';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ICreateSessionRequest } from '@frontend/api';
import { LoaderWrapper } from './EmailVerification.css';

export function EmailVerification() {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const {
    emailVerification,
    isEmailVerificationInProgress,
    isEmailVerificationNotStarted,
    isEmailVerificationSuccess,
    isEmailVerificationFail,
    currentUser,
    isCurrentUserBuyer,
    isCurrentUserSeller,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (emailVerification && isEmailVerificationNotStarted) {
      if (currentUser) {
        if (currentUser.emailVerified) {
          navigate(PLATFORM_ROUTES.buyMetal);
          return;
        }
        const params: ICreateSessionRequest = {
          ...(token && { token }),
        };
        emailVerification(params);
      } else {
        navigate({
          pathname: PLATFORM_ROUTES.buyMetal,
          search: `?source=${LOGIN_LINK_SOURCE}`,
        });
      }
    }
  }, [token, searchParams, emailVerification, navigate]);

  useEffect(() => {
    if (!isEmailVerificationInProgress && !isEmailVerificationNotStarted) {
      if (currentUser) {
        const destination = isCurrentUserBuyer
          ? generatePath(PLATFORM_ROUTES.requestDetails, {
              uuid: currentUser.uuid,
            })
          : PLATFORM_ROUTES.sellerDashboard;

        if (isEmailVerificationSuccess) {
          navigate(destination);
        } else if (isEmailVerificationFail) {
          navigate({
            pathname: destination,
            search: `?source=${RE_VERIFICATION_SOURCE}`,
          });
        }
      } else {
        navigate({
          pathname: PLATFORM_ROUTES.buyMetal,
          search: `?source=${LOGIN_LINK_SOURCE}`,
        });
        return;
      }
    }
  }, [
    isEmailVerificationInProgress,
    isEmailVerificationNotStarted,
    isEmailVerificationSuccess,
    isEmailVerificationFail,
    currentUser,
    isCurrentUserBuyer,
    isCurrentUserSeller,
    navigate,
    searchParams,
  ]);

  if (isEmailVerificationInProgress || isEmailVerificationNotStarted) {
    return (
      <LoaderWrapper>
        <CircularProgress size={160} />
      </LoaderWrapper>
    );
  }
}

export default EmailVerification;
