import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';
import { RequestListWrapperProps } from './RequestList.types';

export const RequestListWrapper = styled(MUIBox, {
  shouldForwardProp: prop => prop !== 'distance',
})<RequestListWrapperProps>(({ theme, distance = 0 }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${distance + 40}px)`, // +40px from padding bottom
  gap: '12px',
  flexBasis: 'calc(100% * (1 / 3) - 12px)', // 12px from gap with sibling
  overflowY: 'auto',
  scrollbarColor: `${theme.palette.primary[100]} transparent`,
  scrollbarWidth: 'thin',

  [theme.breakpoints.down('desktop')]: {
    flexBasis: 'content',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '30px',
    overflowY: 'inherit',
    height: '100%',
  },

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    rowGap: '12px',
  },
}));

export const RequestCardWrapper = styled(MUIBox)(({ theme }) => ({
  cursor: 'pointer',

  [theme.breakpoints.down('desktop')]: {
    cursor: 'default',
    flexBasis: 'calc((100% - 12px)/2)',
  },

  [theme.breakpoints.down('tablet')]: {
    cursor: 'default',
    flexBasis: '100%',
  },
}));
