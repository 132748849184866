export const MAX_FILE_SIZE = 10485760; // 10MB

export const MIN_REQUIRED_FILES = 3;

export const SUPPORTED_FORMATS = [
  'application/pdf',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/heic',
];
