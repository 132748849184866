// @todo: Move this file from @frontend/dev-utils to a new utils library (i.e: @frontend/utils)

const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 5,
});

export const formatNumberToUS = (value: number | string) => {
  const sanitizedNumber = typeof value === 'number' ? value : parseFloat(value);

  return Number.isNaN(sanitizedNumber)
    ? value
    : numberFormatter.format(sanitizedNumber);
};

/**
 * Return numbers under 1000 converted to words.
 * UI/UX wise, small numbers are recommended to be text while big ones should be numbers.
 * @param number
 */
export const convertNumberToWords = (number: number) => {
  if (number >= 1000) {
    return number.toString();
  }

  const numbersToWordsMap = {
    0: 'zero',
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten',
    11: 'eleven',
    12: 'twelve',
    13: 'thirteen',
    14: 'fourteen',
    15: 'fifteen',
    16: 'sixteen',
    17: 'seventeen',
    18: 'eighteen',
    19: 'nineteen',
    20: 'twenty',
    30: 'thirty',
    40: 'forty',
    50: 'fifty',
    60: 'sixty',
    70: 'seventy',
    80: 'eighty',
    90: 'ninety',
  } as Record<number, string>;

  if (number in numbersToWordsMap) return numbersToWordsMap[number];

  let words = '';

  if (number >= 100) {
    words += convertNumberToWords(Math.floor(number / 100)) + ' hundred';
    number %= 100;
  }

  if (number > 0) {
    if (words !== '') words += ' ';

    if (number < 20) words += numbersToWordsMap[number];
    else {
      words += numbersToWordsMap[Math.floor(number / 10) * 10];

      if (number % 10 > 0) {
        words += '-' + numbersToWordsMap[number % 10];
      }
    }
  }

  return words;
};
