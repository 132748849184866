import { ToastAlert } from '@frontend/ui-elements';
import { Translate } from '@frontend/translation';

export default function EmptyOrder() {
  return (
    <ToastAlert
      title={
        <Translate id="customer-platform.request-details.order-list.empty-order-title" />
      }
      message={
        <Translate id="customer-platform.request-details.order-list.empty-order-subtitle" />
      }
      severity="info"
    />
  );
}
