import { forwardRef, Ref } from 'react';
import { MenuItemSt, PaperSt, TextFieldSt } from './Select.css';
import { SelectProps } from './Select.types';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';

const Select = forwardRef(function (
  props: SelectProps,
  ref?: Ref<HTMLDivElement>,
) {
  const {
    id,
    label,
    options,
    required = false,
    helperText,
    error,
    disabled,
    onChange,
    value,
    defaultValue,
    formId,
  } = props;

  return (
    <TextFieldSt
      select
      id={id}
      label={label}
      required={required}
      helperText={helperText}
      error={error}
      disabled={disabled}
      formId={formId}
      ref={ref}
      SelectProps={{
        inputProps: { id: id },
        IconComponent: ArrowDownSLineIcon,
        MenuProps: {
          id: `menu-${id}`,
          PaperProps: {
            component: PaperSt,
          },
          MenuListProps: { disablePadding: true },
        },
        onChange,
        defaultValue,
        value,
      }}
    >
      {options.map(({ label, value }) => (
        <MenuItemSt key={value} value={value}>
          {label}
        </MenuItemSt>
      ))}
    </TextFieldSt>
  );
});

export default Select;
