import { Translate } from '@frontend/translation';
import { EmptySpace } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import {
  ListingDetails,
  ListingDetailsItem,
  ListingNoAndDateWrapper,
  ListingOverview,
  MainWrapper,
} from './ListingCard.css';
import { ListingCardProps } from './ListingCard.types';
import { formatDateWithDots } from '../../../app/shared/utils/date.utils';
import ArrowUpDownLineIcon from 'remixicon-react/ArrowUpDownLineIcon';
import FoldersLineIcon from 'remixicon-react/FoldersLineIcon';
import MapPinLineIcon from 'remixicon-react/MapPinLineIcon';
import MoneyEuroCircleLineIcon from 'remixicon-react/MoneyEuroCircleLineIcon';
import NullableCardDetailsItem from '../../../app/shared/components/Cards/NullableCardDetailsItem/NullableCardDetailsItem';
import RecycleLineIcon from 'remixicon-react/RecycleLineIcon';
import StatusPill from '../../../app/shared/components/Cards/StatusPill/StatusPill';

export default function ListingCard({
  listing: {
    publicId,
    listingNumberId,
    status,
    quantity,
    metalType,
    shippingFrom,
    price,
    incoterms,
    createdDate,
  },
}: ListingCardProps) {
  return (
    <MainWrapper>
      <ListingOverview>
        <ListingNoAndDateWrapper>
          <Typography variant="h6">
            <Translate
              id="customer-platform.listing-list.listing-card.order-number"
              values={{
                LISTING_NUMBER_ID: listingNumberId,
              }}
            />
          </Typography>
          <Typography variant="caption4" component="span">
            <Translate
              id="customer-platform.listing-list.listing-card.created-date"
              values={{
                CREATION_DATE: formatDateWithDots(createdDate),
              }}
            />
            {}
          </Typography>
        </ListingNoAndDateWrapper>
        <StatusPill status={status} />
      </ListingOverview>
      <ListingDetails>
        <ListingDetailsItem aria-label="weight-info">
          <ArrowUpDownLineIcon size={16} />
          <Typography variant="caption1" component="span">
            <Translate id="customer-platform.listing-list.listing-card.weight" />
          </Typography>
          <NullableCardDetailsItem data={quantity}>
            <Typography variant="caption2" component="span">
              {quantity}
              <EmptySpace />
              <Translate id="customer-platform.listing-list.listing-card.weight-unit" />
            </Typography>
          </NullableCardDetailsItem>
        </ListingDetailsItem>
        <ListingDetailsItem aria-label="metal-type-info">
          <RecycleLineIcon size={16} />
          <Typography variant="caption1" component="span">
            <Translate id="customer-platform.listing-list.listing-card.metal-type" />
          </Typography>
          <Typography variant="caption2" component="span">
            <Translate id={`customer-platform.api.${metalType}`} />
          </Typography>
        </ListingDetailsItem>
        <ListingDetailsItem aria-label="incoterm-info">
          <FoldersLineIcon size={16} />
          <Typography variant="caption1" component="span">
            <Translate id="customer-platform.listing-list.listing-card.incoterms" />
          </Typography>
          <Typography variant="caption2" component="span">
            <Translate id={`customer-platform.api.${incoterms}`} />
          </Typography>
        </ListingDetailsItem>
        <ListingDetailsItem aria-label="destination-info">
          <MapPinLineIcon size={16} />
          <Typography variant="caption1" component="span">
            <Translate id="customer-platform.listing-list.listing-card.destination" />
          </Typography>
          <NullableCardDetailsItem data={shippingFrom}>
            <Typography variant="caption2" component="span">
              {shippingFrom}
            </Typography>
          </NullableCardDetailsItem>
        </ListingDetailsItem>
        <ListingDetailsItem aria-label="cost-info">
          <MoneyEuroCircleLineIcon size={16} />
          <Typography variant="caption1" component="span">
            <Translate id="customer-platform.listing-list.listing-card.cost" />
          </Typography>
          <NullableCardDetailsItem data={price}>
            <Typography variant="caption2" component="span">
              {price}
              <EmptySpace />
              <Translate id="customer-platform.listing-list.listing-card.cost-unit" />
            </Typography>
          </NullableCardDetailsItem>
        </ListingDetailsItem>
      </ListingDetails>
    </MainWrapper>
  );
}
