import { formatNumberToUS } from '@frontend/dev-utils';
import { Translate } from '@frontend/translation';
import { AccordionSummary, Button, EmptySpace } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ButtonWrapper,
  ItemTitleValueWrapper,
  RequestAccordion,
  RequestAccordionDetails,
  RequestDetails,
  RequestDetailsItem,
  RequestNoAndDateWrapper,
  RequestOverview,
} from './RequestCard.css';
import { IRequestCard } from './RequestCard.types';
import { formatDateWithDots } from '../../../../../app/shared/utils/date.utils';
import { useRequestCardScroll } from './useRequestCardScroll/useRequestCardScroll';
import ArrowUpDownLineIcon from 'remixicon-react/ArrowUpDownLineIcon';
import FoldersLineIcon from 'remixicon-react/FoldersLineIcon';
import MapPinLineIcon from 'remixicon-react/MapPinLineIcon';
import MoneyDollarCircleLineIcon from 'remixicon-react/MoneyDollarCircleLineIcon';
import NullableCardDetailsItem from '../../../../../app/shared/components/Cards/NullableCardDetailsItem/NullableCardDetailsItem';
import RecycleLineIcon from 'remixicon-react/RecycleLineIcon';
import StatusPill from '../../../../../app/shared/components/Cards/StatusPill/StatusPill';

export default function RequestCard({
  request: {
    publicId,
    requestNumberId,
    status,
    quantity,
    metalType,
    shippingPort,
    targetPrice,
    incoterms,
    createdDate,
  },
  expanded,
  onClick,
}: IRequestCard) {
  const theme = useTheme();
  const isDesktopOrBigger = useMediaQuery(theme.breakpoints.up('desktop'));
  const isTabletOrSmaller = !isDesktopOrBigger;

  const { requestCardRef } = useRequestCardScroll({
    enableScroll: !!expanded && isDesktopOrBigger,
  });

  return (
    <RequestAccordion
      expanded={expanded}
      id={`request-card.accordion.${publicId}`}
      onClick={isDesktopOrBigger && !expanded ? onClick : undefined}
      square
      ref={requestCardRef}
    >
      <AccordionSummary showActionButton={isDesktopOrBigger}>
        <RequestOverview>
          <RequestNoAndDateWrapper>
            <Typography variant="h6">
              <Translate
                id="customer-platform.request-list.request-card.order-number"
                values={{
                  REQUEST_NUMBER_ID: requestNumberId,
                }}
              />
            </Typography>
            <Typography variant="caption4" component="span">
              <Translate
                id="customer-platform.request-list.request-card.created-date"
                values={{
                  CREATION_DATE: formatDateWithDots(createdDate),
                }}
              />
            </Typography>
          </RequestNoAndDateWrapper>
          <StatusPill status={status} />
        </RequestOverview>
      </AccordionSummary>

      <RequestAccordionDetails>
        <RequestDetails>
          <RequestDetailsItem aria-label="weight-info">
            <ArrowUpDownLineIcon size={16} />
            <ItemTitleValueWrapper>
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.request-list.request-card.weight" />
              </Typography>
              <NullableCardDetailsItem data={quantity}>
                <Typography variant="caption2" component="span">
                  {formatNumberToUS(quantity as number)}
                  <EmptySpace />
                  <Translate id="customer-platform.request-list.request-card.weight-unit" />
                </Typography>
              </NullableCardDetailsItem>
            </ItemTitleValueWrapper>
          </RequestDetailsItem>

          <RequestDetailsItem aria-label="metal-type-info">
            <RecycleLineIcon size={16} />
            <ItemTitleValueWrapper>
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.request-list.request-card.metal-type" />
              </Typography>
              <Typography variant="caption2" component="span">
                <Translate id={`customer-platform.api.${metalType}`} />
              </Typography>
            </ItemTitleValueWrapper>
          </RequestDetailsItem>

          <RequestDetailsItem aria-label="incoterms-info">
            <FoldersLineIcon size={16} />
            <ItemTitleValueWrapper>
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.request-list.request-card.incoterms" />
              </Typography>
              <NullableCardDetailsItem data={incoterms}>
                <Typography variant="caption2" component="span">
                  <Translate id={`customer-platform.api.${incoterms}`} />
                </Typography>
              </NullableCardDetailsItem>
            </ItemTitleValueWrapper>
          </RequestDetailsItem>

          <RequestDetailsItem aria-label="destination-info">
            <MapPinLineIcon size={16} />
            <ItemTitleValueWrapper>
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.request-list.request-card.destination" />
              </Typography>
              <NullableCardDetailsItem data={shippingPort}>
                <Typography variant="caption2" component="span">
                  {shippingPort}
                </Typography>
              </NullableCardDetailsItem>
            </ItemTitleValueWrapper>
          </RequestDetailsItem>

          <RequestDetailsItem aria-label="cost-info">
            <MoneyDollarCircleLineIcon size={16} />
            <ItemTitleValueWrapper>
              <Typography variant="caption1" component="span">
                <Translate id="customer-platform.request-list.request-card.cost" />
              </Typography>
              <NullableCardDetailsItem data={targetPrice}>
                <Typography variant="caption2" component="span">
                  {formatNumberToUS(targetPrice as number)}
                  <EmptySpace />
                  <Translate id="customer-platform.request-list.request-card.cost-unit" />
                </Typography>
              </NullableCardDetailsItem>
            </ItemTitleValueWrapper>
          </RequestDetailsItem>
        </RequestDetails>
        {isTabletOrSmaller && (
          <ButtonWrapper>
            <Button
              buttonType="primary"
              id={`request-card.button.open-request-${publicId}`}
              size="small"
              onClick={onClick}
            >
              <Translate id="customer-platform.request-list.request-card.cta" />
            </Button>
          </ButtonWrapper>
        )}
      </RequestAccordionDetails>
    </RequestAccordion>
  );
}
