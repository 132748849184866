import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';

export const useGetContainerImageURL = () => {
  const { apiUrl } = useContext(ApiContext);
  const getContainerImageURL = (containerId: string, imageId: string) => {
    return `${apiUrl}/orders/containers/${containerId}/loading-photos/${imageId}/download/`;
  };
  return { getContainerImageURL };
};

export const useGetBiddingImageURL = () => {
  const { apiUrl } = useContext(ApiContext);
  const getBiddingImageURL = (listingId: string, imageId: string) => {
    return `${apiUrl}/trading/listings/${listingId}/photos/${imageId}/download/`;
  };
  return { getBiddingImageURL };
};
