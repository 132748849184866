import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import fileSaver from 'file-saver';

const useDownloadAllContainerImage = () => {
  const { apiUrl } = useContext(ApiContext);
  const downloadAllContainerImage = async (
    containerId: string,
    containerNumber: string,
  ) => {
    return axiosClient
      .get(
        `${apiUrl}/orders/containers/${containerId}/loading-photos/download/`,
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        fileSaver.saveAs(
          response.data,
          `container_${containerNumber}_photos.zip`,
        );
      })
      .catch(error => error);
  };

  return { downloadAllContainerImage };
};
export default useDownloadAllContainerImage;
