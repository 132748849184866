import { styled } from '@frontend/theme';
import {
  Container as MUIContainer,
  StepConnector as MUIStepConnector,
  stepConnectorClasses,
} from '@mui/material';

export const StatusBarContainer = styled(MUIContainer)(({ theme }) => ({
  padding: '20px 0',
  [theme.breakpoints.down('tablet')]: {
    padding: '0',
  },
}));

export const StepConnector = styled(MUIStepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary[500],
    },
  },
  [`&.${stepConnectorClasses.root}`]: {
    paddingLeft: '8px',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: '2px',
    borderRadius: 0,
    borderColor: theme.palette.neutral[50],
    width: 'calc(100% + 24px)',
  },
}));
