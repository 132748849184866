import { Translate } from '@frontend/translation';
import { Modal } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import MailSendLineIcon from 'remixicon-react/MailSendLineIcon';
import {
  IconButton,
  IconWrapper,
  ModalBox,
  ModalSuccessBox,
  ModalSuccessHeadingBox,
} from './EmailVerificationModal.css';
import { IEmailVerificationModal } from './EmailVerificationModal.types';
import { useIntl } from 'react-intl';

export function EmailVerificationModal({
  isOpen,
  onClose,
}: IEmailVerificationModal) {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalBox>
        <IconButton
          id="email-verification-modal.icon-button.close-modal"
          aria-label={intl.formatMessage({
            id: 'customer-platform.shared.email-verification.modal-box.close',
          })}
          onClick={onClose}
        >
          <CloseLineIcon size={24} color={theme.palette.neutral[500]} />
        </IconButton>
        <ModalSuccessBox>
          <IconWrapper>
            <MailSendLineIcon size={77.28} color={theme.palette.neutral[500]} />
            <MailSendLineIcon
              size={77.28}
              color={theme.palette.secondary[500]}
            />
          </IconWrapper>

          <ModalSuccessHeadingBox>
            <Typography variant="h2">
              <Translate id="customer-platform.shared.email-verification.email-sent.heading" />
            </Typography>
            <Typography variant="p3">
              <Translate id="customer-platform.shared.email-verification.email-sent.subheading" />
            </Typography>
          </ModalSuccessHeadingBox>
        </ModalSuccessBox>
      </ModalBox>
    </Modal>
  );
}

export default EmailVerificationModal;
