import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const MainWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'flex-start',
  padding: '8px 16px',
  width: '100%',
  backgroundColor: theme.palette.tertiary[500],
  '& > svg': {
    minWidth: '24px',
  },
}));
