import { styled } from '@frontend/theme';
import { Container as MUIContainer } from '@mui/material';
import { IMainWrapper } from './StatusPill.types';

const getBackgroundColor = ({ theme, status }: IMainWrapper) => {
  switch (status) {
    case 'request.status.pending':
    case 'listing.status.pending':
      return theme?.palette.tertiary[500];
    case 'request.status.in_progress':
    case 'listing.status.in_progress':
      return theme?.palette.secondary[600];
    case 'request.status.cancelled':
    case 'listing.status.cancelled':
      return theme?.palette.neutral[400];
    case 'request.status.accepted':
    case 'listing.status.completed':
      return theme?.palette.primary[500];
  }
};

const getTextColor = ({ theme, status }: IMainWrapper) => {
  switch (status) {
    case 'request.status.pending':
    case 'listing.status.pending':
      return theme?.palette.neutral[500];
    case 'request.status.in_progress':
    case 'listing.status.in_progress':
      return theme?.palette.neutral[500];
    case 'request.status.cancelled':
    case 'listing.status.cancelled':
      return theme?.palette.common.white;
    case 'request.status.accepted':
    case 'listing.status.completed':
      return theme?.palette.common.white;
  }
};

export const MainWrapper = styled(MUIContainer)(
  ({ theme, status }: IMainWrapper) => ({
    width: 'auto',
    margin: 0,
    padding: '4px 8px',
    backgroundColor: getBackgroundColor({ theme, status }),
    color: getTextColor({ theme, status }),
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    whiteSpace: 'nowrap',
  }),
);
