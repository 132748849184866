import { DownloadAllDocumentsProps } from './DownloadAllDocuments.types';
import { Button } from '@frontend/ui-elements';
import { Translate } from '@frontend/translation';
import { useGetAllDocument } from '@frontend/api';

export default function DownloadAllDocuments({
  documentContextList,
  id = 'button.download-all-documents',
}: DownloadAllDocumentsProps) {
  const { downloadAllDocument } = useGetAllDocument();
  const handleDownloadAll = async () => {
    const { parentRecordId, parentRecordType, parentRecordPublicId } =
      documentContextList[0];
    await downloadAllDocument(
      parentRecordId,
      parentRecordType,
      parentRecordPublicId,
    );
  };

  if (!documentContextList.length) {
    return <></>;
  }

  return (
    <Button
      buttonType="linked"
      id={id}
      size="large"
      onClick={handleDownloadAll}
    >
      <Translate id="customer-platform.request-details.order-card.download-all" />
    </Button>
  );
}
