import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import axiosClient from '../axiosClient';
import fileSaver from 'file-saver';
import { IContainerPhoto } from '../getContainerDetails/getContainerDetails.types';

const useDownloadContainerImage = () => {
  const { apiUrl } = useContext(ApiContext);
  const downloadContainerImage = async (
    containerId: string,
    image: IContainerPhoto,
  ) => {
    return axiosClient
      .get(
        `${apiUrl}/orders/containers/${containerId}/loading-photos/${image.uuid}/download/`,
        {
          responseType: 'blob',
        },
      )
      .then(response => {
        fileSaver.saveAs(response.data, `${image.name}.${image.type}`);
      });
  };

  return { downloadContainerImage };
};
export default useDownloadContainerImage;
