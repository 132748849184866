import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { PlaceholderImageProps } from './PlaceholderImage.types';
import {
  PlaceholderImageWrapper,
  PlaceholderSlideWrapper,
} from './PlaceholderImage.css';
import HourglassImage from './hourglass-line.svg';
import { ImageWrapper } from '../../../ContainerCard.css';

export default function PlaceholderImage({
  icon,
  caption,
  onClick,
}: PlaceholderImageProps) {
  const intl = useIntl();

  return (
    <PlaceholderImageWrapper data-testid="placeholder-image" onClick={onClick}>
      <ImageWrapper>
        {icon === 'hourglass' && (
          <img
            srcSet={HourglassImage}
            src={HourglassImage}
            alt={intl.formatMessage({
              id: 'customer-platform.request-details.order-list.container-drawer.container-photos.placeholder-image-alt',
            })}
            loading="lazy"
          />
        )}
      </ImageWrapper>
      <Typography variant="p4">{caption}</Typography>
    </PlaceholderImageWrapper>
  );
}

export function PlaceholderSlide({ icon, caption }: PlaceholderImageProps) {
  const intl = useIntl();

  return (
    <PlaceholderSlideWrapper data-testid="placeholder-image">
      <ImageWrapper>
        {icon === 'hourglass' && (
          <img
            srcSet={HourglassImage}
            src={HourglassImage}
            alt={intl.formatMessage({
              id: 'customer-platform.request-details.order-list.container-drawer.container-photos.placeholder-image-alt',
            })}
            loading="lazy"
          />
        )}
      </ImageWrapper>
      <Typography variant="p1">{caption}</Typography>
    </PlaceholderSlideWrapper>
  );
}
