import { Typography, useTheme } from '@mui/material';
import humanFileSize from './UploadedFile.utils';
import { IUploadedFile } from './UploadedFile.types';
import { FileDetailsWrapper, UploadedFileWrapper } from './UploadedFile.css';
import DeleteBin7LineIcon from 'remixicon-react/DeleteBin7LineIcon';
import IconButton from '../../IconButton/IconButton';
import Image2LineIcon from 'remixicon-react/Image2LineIcon';

export default function UploadedFile({
  error,
  errorMessage,
  file,
  onDelete,
}: IUploadedFile) {
  const theme = useTheme();

  return (
    <UploadedFileWrapper>
      <Image2LineIcon
        size={'24px'}
        color={!error ? theme.palette.neutral[400] : theme.palette.error[500]}
      />
      <FileDetailsWrapper>
        <Typography
          variant="p1"
          color={!error ? theme.palette.neutral[500] : theme.palette.error[500]}
        >
          {file.name}
        </Typography>
        <Typography
          variant="p3"
          color={!error ? theme.palette.neutral[500] : theme.palette.error[500]}
        >
          {errorMessage ? errorMessage : humanFileSize(file.size)}
        </Typography>
      </FileDetailsWrapper>
      <IconButton onClick={onDelete}>
        <DeleteBin7LineIcon size={'24px'} color={theme.palette.neutral[500]} />
      </IconButton>
    </UploadedFileWrapper>
  );
}
