import { SUPPORTED_IMAGE_FORMATS, useGetBiddingImageURL } from '@frontend/api';
import { Typography } from '@mui/material';
import {
  ListingImageListWrapper,
  ListingImageMainWrapper,
} from './BiddableListingImageList.css';
import { BiddableListingImagesProps } from './BiddableListingImageList.types';
import BiddableListingImageCard from './BiddableListingImageCard/BiddableListingImageCard';
import { Translate } from '@frontend/translation';
import ImagesLightbox from '../../../app/shared/components/ImagesLightbox/ImagesLightbox';
import { useState } from 'react';
import { isFileTypeIncluded } from '../../../app/shared/utils/fileType.utils';
import { trackClickEvent } from '@frontend/tracking';

const BiddableListingImageList = ({
  uuid,
  productImages,
}: BiddableListingImagesProps) => {
  const supportedImages =
    productImages?.filter(image =>
      isFileTypeIncluded(image.fileType || '', SUPPORTED_IMAGE_FORMATS),
    ) || [];

  const [lightboxActive, setLightboxActive] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { getBiddingImageURL } = useGetBiddingImageURL();
  const openLightbox = (clickedImageIndex: number, uuid: string) => {
    trackClickEvent({
      elementId: `biddable-listing.image-list.${uuid}`,
    });
    setSelectedImageIndex(clickedImageIndex);
    setLightboxActive(true);
  };

  return (
    supportedImages.length > 0 && (
      <ListingImageMainWrapper>
        <Typography component="h4" variant="p1">
          <Translate id="customer-platform.biddable-listing-page.images-title" />
        </Typography>
        <ListingImageListWrapper>
          {supportedImages.map((image, imageIndex) => (
            <BiddableListingImageCard
              key={image.uniqueIdentifier}
              listingId={uuid}
              imageId={image.uniqueIdentifier}
              onClick={() => openLightbox(imageIndex, uuid)}
            />
          ))}
        </ListingImageListWrapper>

        <ImagesLightbox
          slidesList={supportedImages.map(biddingImage => {
            return { uuid: biddingImage.uniqueIdentifier };
          })}
          isOpen={lightboxActive}
          closeLightbox={() => setLightboxActive(false)}
          imageIndex={selectedImageIndex}
          getImageURLFromId={elementId => getBiddingImageURL(uuid, elementId)}
        />
      </ListingImageMainWrapper>
    )
  );
};

export default BiddableListingImageList;
