import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const MainWrapper = styled(MUIBox)({
  display: 'flex',
  gap: '16px 12px',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxWidth: '970px',
  width: '100%',
});

export const RemainingImagesWrapper = styled(MUIBox)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  cursor: 'pointer',
  maxWidth: 'calc(100%/5 - 12px)',
  [theme.breakpoints.down('tablet')]: {
    maxWidth: 'calc(100%/4 - 12px)',
  },
  '& > div': {
    maxWidth: '100%',
  },
}));

export const RemainingImagesText = styled(MUIBox)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
});

export const ContainerPDFImagesWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
}));

export const ContainerPDFImagesButtonsWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'row',
  gap: '20px',
  gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  [theme.breakpoints.down('tabletL')]: {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  },
  [theme.breakpoints.down('tablet')]: {
    display: 'flex',
    flexDirection: 'column',
  },
  '& > div': {
    maxWidth: '180px',
  },
  '& span': {
    wordBreak: 'break-all',
  },
}));

export const Overlay = styled(MUIBox)(({ theme }) => ({
  color: theme.palette.common.white,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: '#00000071',
  pointerEvents: 'none',
  position: 'absolute',
}));
