import { IContainerPhoto, useDownloadContainerImage } from '@frontend/api';
import { Translate } from '@frontend/translation';
import { Icon } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import { DocumentContainer } from '../../../ContainerDocuments/ContainerDocuments.css';
import { ContainerImageListProps } from '../ContainerImageList.types';
import {
  ContainerPDFImagesWrapper,
  ContainerPDFImagesButtonsWrapper,
} from '../ContainerImageList.css';
import { trackClickEvent } from '@frontend/tracking';

export function ContainerPDFImagesList({
  containerId,
  imageList,
}: ContainerImageListProps) {
  const { downloadContainerImage } = useDownloadContainerImage();

  const handleDownloadImage = async (image: IContainerPhoto) => {
    trackClickEvent({
      elementId: `container-drawer.pdf-image.${image.name}`,
    });
    await downloadContainerImage(containerId, image);
  };

  return (
    <ContainerPDFImagesWrapper data-testid="container-pdf-images-list">
      <Typography variant="h6">
        <Translate
          id={
            'customer-platform.request-details.order-list.container-drawer.other-container-images.title'
          }
        />
      </Typography>
      <ContainerPDFImagesButtonsWrapper>
        {imageList.map(image => {
          return (
            <DocumentContainer
              data-testid={'container-pdf-image'}
              key={image.uuid}
              id={`container-documents.button.${image.name}`}
              onClick={() => handleDownloadImage(image)}
            >
              <Icon size={24} variant="pdf" />
              <Typography variant="p2" noWrap component="span">
                {image.name}
              </Typography>
            </DocumentContainer>
          );
        })}
      </ContainerPDFImagesButtonsWrapper>
    </ContainerPDFImagesWrapper>
  );
}

export default ContainerPDFImagesList;
