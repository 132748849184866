import { Tab, Tabs, useTabs } from '@frontend/ui-elements';
import { Typography } from '@mui/material';
import {
  LocationEventTabPanel,
  LocationEventTabsWrapper,
} from './LocationEventTabs.css';
import { LocationEventTabsProps } from './LocationEventTabs.types';
import RouteEventList from './RouteEventList/RouteEventList';
import VesselList from './VesselList/VesselList';
import { Translate } from '@frontend/translation';

const LocationEventTabs = ({ events, vessels }: LocationEventTabsProps) => {
  const { handleChange, value: selectedTab } = useTabs({ initialTabIndex: 0 });

  return (
    <LocationEventTabsWrapper>
      <Tabs value={selectedTab} onChange={handleChange} selectionFollowsFocus>
        <Tab
          aria-controls="route-tabpanel"
          id="location-events.tab.route-tab"
          label={
            <Typography variant="p2">
              <Translate
                id={
                  'customer-platform.request-details.order-list.container-drawer.container-tracking.location-event-tabs.route-tab.tab-name'
                }
              />
            </Typography>
          }
          wrapped
        />
        <Tab
          aria-controls="vessel-tabpanel"
          id="location-events.tab.vessel-tab"
          label={
            <Typography variant="p2">
              <Translate
                id={
                  'customer-platform.request-details.order-list.container-drawer.container-tracking.location-event-tabs.vessel-tab.tab-name'
                }
              />
            </Typography>
          }
          wrapped
        />
      </Tabs>
      <LocationEventTabPanel
        index={0}
        value={selectedTab}
        aria-labelledby="route-tab"
      >
        <RouteEventList events={events} />
      </LocationEventTabPanel>
      <LocationEventTabPanel
        index={1}
        value={selectedTab}
        aria-labelledby="vessel-tab"
      >
        <VesselList vessels={vessels} />
      </LocationEventTabPanel>
    </LocationEventTabsWrapper>
  );
};

export default LocationEventTabs;
