import { Translate } from '@frontend/translation';
import { Button, Modal } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import AlertLineIcon from 'remixicon-react/AlertLineIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import {
  IconWrapper,
  ModalBox,
  ModalHeadingBox,
  ModalTextBox,
  IconButton,
} from './LoginLinkExpiredModal.css';
import { ILoginLinkExpiredModal } from './LoginLinkExpiredModal.types';

export default function LoginLinkExpiredModal({
  onClose,
  onClickOpenLoginModal,
}: ILoginLinkExpiredModal) {
  const theme = useTheme();

  return (
    <Modal open={true}>
      <ModalBox>
        <ModalHeadingBox>
          <IconButton
            id="login-link-expired-modal.icon-button.close-modal"
            onClick={onClose}
          >
            <CloseLineIcon size={24} color={theme.palette.neutral[500]} />
          </IconButton>
          <IconWrapper>
            <AlertLineIcon size={76} color={theme.palette.neutral[500]} />
            <AlertLineIcon size={76} color={theme.palette.secondary[500]} />
          </IconWrapper>

          <ModalTextBox>
            <Typography variant="h2">
              <Translate id="customer-platform.shared.login.login-link-expired-modal.link-expired" />
            </Typography>
            <Typography variant="p3">
              <Translate id="customer-platform.shared.login.login-link-expired-modal.request-login-again" />
            </Typography>
          </ModalTextBox>
        </ModalHeadingBox>

        <Button
          buttonType="primary"
          id="login-link-expired-modal.button.login-again"
          size="large"
          onClick={onClickOpenLoginModal}
        >
          <Translate id="customer-platform.shared.login.login-link-expired-modal.cta" />
        </Button>
      </ModalBox>
    </Modal>
  );
}
