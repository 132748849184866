import { format } from 'date-fns';

export const formatDateWithDots = (date: string) => {
  return format(new Date(date), 'dd.MMM.yyyy');
};

export const formatDateWithSpaces = (date: string) => {
  return format(new Date(date), 'dd MMM yyyy');
};

export const formatUnixTimestampToDateWithSpaces = (
  timestamp: number,
  withTime?: boolean,
) => {
  return withTime
    ? format(new Date(timestamp * 1000), 'dd MMM yyyy HH:mm')
    : formatDateWithSpaces(new Date(timestamp * 1000).toISOString());
};
