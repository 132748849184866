import { MainWrapper } from './PricePill.css';
import { Typography } from '@mui/material';
import { Translate } from '@frontend/translation';
import MoneyDollarCircleLineIcon from 'remixicon-react/MoneyDollarCircleLineIcon';

export default function PricePill() {
  return (
    <MainWrapper>
      <MoneyDollarCircleLineIcon size={18} />
      <Typography variant="p4">
        <Translate id="customer-platform.request-details.order-card.calculating-price" />
      </Typography>
    </MainWrapper>
  );
}
