import {
  Accordion,
  AccordionDetails,
  SideWrapper,
} from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  AccordionSummary,
  OrderMobileWrapper,
  OrderWrapper,
} from './OrderCard.css';
import { IOrderCard } from './OrderCard.types';
import OrderCardDetails from './OrderCardDetails/OrderCardDetails';
import OrderOverview from './OrderOverview/OrderOverview';
import { useOrderCardScroll } from './useOrderCardScroll/useOrderCardScroll';

export default function OrderCard({
  order,
  onChange,
  isOrderSelected,
}: IOrderCard) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const { orderCardRef, ...refs } = useOrderCardScroll({
    enableScroll: !!isOrderSelected,
  });

  if (isMobile) {
    if (isOrderSelected) {
      return (
        <OrderMobileWrapper ref={orderCardRef}>
          <OrderOverview order={order} isMobile={isMobile} isOrderSelected />
          <SideWrapper>
            <OrderCardDetails order={order} refs={refs} />
          </SideWrapper>
        </OrderMobileWrapper>
      );
    } else {
      return (
        <OrderWrapper ref={orderCardRef} key={order.uuid} onClick={onChange}>
          <OrderOverview order={order} isMobile={isMobile} />
        </OrderWrapper>
      );
    }
  }

  return (
    <OrderWrapper key={order.uuid} ref={orderCardRef}>
      <Accordion
        expanded={isOrderSelected}
        id={`order-card.accordion.${order.publicId}.${
          isOrderSelected ? 'close' : 'open'
        }`}
        data-testid={`test-order-card.accordion.${order.publicId}`}
        onChange={onChange}
        square
      >
        <AccordionSummary showActionButton={true}>
          <OrderOverview order={order} />
        </AccordionSummary>
        <AccordionDetails>
          <OrderCardDetails order={order} refs={refs} />
        </AccordionDetails>
      </Accordion>
    </OrderWrapper>
  );
}
