import { generatePath, Navigate } from 'react-router-dom';
import { useGetRequestList } from '@frontend/api';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { FullPageLoader, PageWrapper } from '@frontend/ui-elements';
import { useMediaQuery, useTheme } from '@mui/material';
import { PageContent } from './BuyerDashboard.css';
import { PageCommonProps } from './BuyerDashboard.types';
import BuyerDashboardHeader from './BuyerDashboardHeader/BuyerDashboardHeader';
import EmptyDashboard from './EmptyDashboard/EmptyDashboard';
import PlatformHeader from '../../app/shared/components/PlatformHeader/PlatformHeader';
import RequestList from './RequestDetails/RequestList/RequestList';
import { HEADER_VARIANTS } from '../../app/shared/components/PlatformHeader/PlatformHeader.types';

export default function BuyerDashboard() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const { status: getRequestListStatus, data: requestListResponse } =
    useGetRequestList();

  const PageCommon = ({ children }: PageCommonProps) => {
    return (
      <>
        <PlatformHeader variant={HEADER_VARIANTS.BUYER} />
        <PageWrapper>{children}</PageWrapper>
      </>
    );
  };

  if (getRequestListStatus === 'success') {
    if (!requestListResponse?.data.length) {
      return (
        <PageCommon>
          <EmptyDashboard />
        </PageCommon>
      );
    }
    if (isDesktop) {
      const firstRequest = requestListResponse?.data[0];
      const firstRequestDetailsPage = generatePath(
        PLATFORM_ROUTES.requestDetails,
        { uuid: firstRequest?.uuid },
      );
      return <Navigate to={firstRequestDetailsPage} replace />;
    } else {
      return (
        <PageCommon>
          <PageContent>
            <BuyerDashboardHeader
              requestsCount={requestListResponse.data.length}
            />
            <RequestList requests={requestListResponse.data} />
          </PageContent>
        </PageCommon>
      );
    }
  }

  if (getRequestListStatus === 'pending') {
    return <FullPageLoader />;
  }
}
