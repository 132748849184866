import { styled } from '@frontend/theme';
import { Box as MUIBox, Container as MUIContainer } from '@mui/material';

export const Container = styled(MUIContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  width: '100%',
}));

export const FileHelperContainer = styled(MUIBox)(() => ({
  display: 'flex',
  flexFlow: 'column',
}));

export const FileSelectorWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 0',
}));

export const FileSelectorLabel = styled('label')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',

  '& > .MuiTypography-root': {
    textDecoration: 'underline',
  },

  '& > input': {
    display: 'none',
  },
}));

export const FileSeparator = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '1px',
  borderRadius: '4px',
  background: theme.palette.neutral[50],
}));
