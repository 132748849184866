import { styled } from '@frontend/theme';
import { Box as MUIBox, Container as MUIContainer } from '@mui/material';

export const MainWrapper = styled(MUIContainer)(({ theme }) => ({
  display: 'flex',
  padding: '20px 20px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  border: `1px solid ${theme.palette.primary[50]}`,
  background: `${theme.palette.common.white}`,
}));

export const ListingOverview = styled(MUIBox)(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
}));

export const ListingNoAndDateWrapper = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'start',
}));

export const ListingDetails = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const ListingDetailsItem = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  padding: '8px 0px',
  ':not(:last-of-type)': {
    borderBottom: `1px solid ${theme?.palette.neutral[50]}`,
  },
  '>': {
    svg: {
      marginRight: '12px',
    },
    'span:nth-of-type(1)': {
      marginRight: '8px',
    },
  },
}));
