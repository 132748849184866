import { useGetListingImage } from '@frontend/api';
import { ListingImageWrapper } from './BiddableListingImageCard.css';
import { BiddableListingImageCardProps } from './BiddableListingImageCard.types';
import { CircularProgress } from '@mui/material';

const BiddableListingImageCard = ({
  listingId,
  imageId,
  onClick,
}: BiddableListingImageCardProps) => {
  const { status, data } = useGetListingImage(listingId, imageId);

  return (
    <ListingImageWrapper>
      {status === 'success' && (
        <img
          src={URL.createObjectURL(data.data)}
          alt={imageId}
          loading="lazy"
          onClick={onClick}
        />
      )}
      {status === 'pending' && <CircularProgress />}
    </ListingImageWrapper>
  );
};

export default BiddableListingImageCard;
