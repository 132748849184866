import TimerLineIcon from 'remixicon-react/TimerLineIcon';
import CheckDoubleLineIcon from 'remixicon-react/CheckDoubleLineIcon';
import Loader4FillIcon from 'remixicon-react/Loader4FillIcon';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import { IListing, IRequest } from '@frontend/api';
import { RemixiconReactIconComponentType } from 'remixicon-react';

export const statusIconMapping: Record<
  IRequest['status'] | IListing['status'],
  RemixiconReactIconComponentType
> = {
  'request.status.cancelled': CloseLineIcon,
  'request.status.accepted': CheckDoubleLineIcon,
  'request.status.in_progress': Loader4FillIcon,
  'request.status.pending': TimerLineIcon,
  'listing.status.cancelled': CloseLineIcon,
  'listing.status.completed': CheckDoubleLineIcon,
  'listing.status.in_progress': Loader4FillIcon,
  'listing.status.pending': TimerLineIcon,
};
