import { styled } from '@frontend/theme';
import { MuiTelInput as MUIPhoneField } from 'mui-tel-input';

export const MUIPhoneFieldSt = styled(MUIPhoneField)(({ theme }) => ({
  '.MuiInputAdornment-root': {
    margin: '0 0 0 8px',
    '& > .MuiButtonBase-root': {
      padding: '8px',
    },
  },

  '.MuiOutlinedInput-root': {
    borderRadius: 0,
    padding: 0,

    '&.Mui-focused': {
      '.MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        borderColor: theme.palette.primary[500],
      },
    },
    '&.Mui-error': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error[500],
        '&:hover': {
          borderColor: theme.palette.error[500],
        },
      },
    },

    '&.Mui-disabled': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.neutral[100],
        '&:hover': {
          borderColor: theme.palette.neutral[100],
        },
      },
    },

    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.neutral[200],
      legend: {
        fontSize: '16px',
      },
      '&:hover': {
        borderColor: theme.palette.neutral[500],
      },
    },

    '.MuiOutlinedInput-input': {
      color: theme.palette.neutral[500],
      padding: '12px 12px 12px 8px',
      '&.Mui-disabled': {
        color: theme.palette.neutral[100],
      },
    },
  },

  '.MuiFormHelperText-root': {
    fontSize: '16px',
    '&.Mui-error': {
      color: theme.palette.error[500],
    },
    '&.Mui-disabled': {
      color: theme.palette.neutral[100],
    },
  },

  '.MuiFormLabel-root': {
    color: theme.palette.neutral[500],
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '26px',
    transform: 'translate(52px, 13px) scale(1)',

    '&.Mui-focused.MuiInputLabel-animated': {
      color: theme.palette.primary[500],
      transform: 'translate(16px, -14px) scale(1)',
    },

    '&.MuiFormLabel-filled.MuiInputLabel-animated': {
      transform: 'translate(16px, -14px) scale(1)',
    },
  },
}));
