import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

export const ListingImageListWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px 12px',
  width: '100%',
});

export const ListingImageMainWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'flex-start',
  width: '100%',
});
