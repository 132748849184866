import IconButton from '../../IconButton/IconButton';
import { Typography, useTheme } from '@mui/material';
import {
  BodyMobileStatusWrapper,
  HeadingMobileStatusWrapper,
  MobileStatusBox,
} from './MobileStatusBar.css';
import MobileStatusBarProps from './MobileStatusBar.types';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import ProgressBar from '../../ProgressBar/ProgressBar';
import StepsBar from '../../StepsBar/StepsBar';

function MobileStatusBar({
  activeStep,
  onClick,
  open,
  stages,
}: MobileStatusBarProps) {
  const theme = useTheme();

  return (
    <>
      <MobileStatusBox>
        <Typography
          component="p"
          variant="caption4"
          color={theme.palette.common.white}
        >
          {`Step ${(
            activeStep + 1
          )?.toString()} of ${stages?.length?.toString()}`}
        </Typography>
        <HeadingMobileStatusWrapper>
          <Typography
            component="h6"
            variant="p1"
            color={theme.palette.common.white}
          >
            {stages.find(stage => stage?.order === activeStep)?.label}
          </Typography>
          <IconButton
            id={`mobile-status-bar.icon-button.${open ? 'close' : 'open'}`}
            onClick={onClick}
          >
            {open ? (
              <ArrowUpSLineIcon size={24} color={theme.palette.common.white} />
            ) : (
              <ArrowDownSLineIcon
                size={24}
                color={theme.palette.common.white}
              />
            )}
          </IconButton>
        </HeadingMobileStatusWrapper>
        <ProgressBar value={((activeStep + 1) / stages.length) * 100} />
      </MobileStatusBox>
      {open && (
        <BodyMobileStatusWrapper>
          <StepsBar
            activeStep={activeStep}
            connector={null}
            stages={stages}
            orientation="vertical"
          />
        </BodyMobileStatusWrapper>
      )}
    </>
  );
}

export default MobileStatusBar;
