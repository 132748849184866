import { ChangeEvent, Fragment } from 'react';
import { Typography, useTheme } from '@mui/material';
import {
  Container,
  FileHelperContainer,
  FileSelectorLabel,
  FileSelectorWrapper,
  FileSeparator,
} from './FilePicker.css';
import { IFilePicker } from './FilePicker.types';
import FileUploadLineIcon from 'remixicon-react/FileUploadLineIcon';
import UploadedFile from './UploadedFile/UploadedFile';

export default function FilePicker({
  errorMessageList,
  fileUploadButtonText,
  helperText,
  id,
  label,
  onChange,
  required,
  value: uploadedFiles = [],
}: IFilePicker) {
  const theme = useTheme();

  const handleFileEvent = (e: ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files;
    files && onChange([...uploadedFiles, ...Array.from(files)]);
  };

  const deleteFile = (index: number) => {
    const fileArrayWithRemovedFiles = uploadedFiles.filter((_, i) => {
      return index !== i;
    });
    onChange(fileArrayWithRemovedFiles);
  };

  return (
    <Container>
      <FileSelectorWrapper>
        <Typography variant="p1">
          {label}
          {required ? '*' : ''}
        </Typography>
        <FileSelectorLabel>
          <FileUploadLineIcon size="24px" color={theme.palette.primary[500]} />
          <Typography variant="p1" color={theme.palette.primary[500]}>
            {fileUploadButtonText}
          </Typography>
          <input
            id={id}
            type="file"
            multiple={true}
            onChange={handleFileEvent}
          />
        </FileSelectorLabel>
      </FileSelectorWrapper>

      {helperText && (
        <FileHelperContainer>
          <Typography variant="p3" color={theme.palette.error[500]}>
            {helperText}
          </Typography>
        </FileHelperContainer>
      )}

      {uploadedFiles &&
        uploadedFiles.map((file: File, index: number) => {
          return (
            <Fragment key={index}>
              <UploadedFile
                file={file}
                onDelete={() => deleteFile(index)}
                error={Boolean(errorMessageList && index in errorMessageList)}
                errorMessage={
                  errorMessageList && index in errorMessageList
                    ? errorMessageList[index]?.message
                    : undefined
                }
              />
              {index + 1 < uploadedFiles.length && <FileSeparator />}
            </Fragment>
          );
        })}
    </Container>
  );
}
