import { Button, Modal } from '@frontend/ui-elements';
import { Typography, useTheme } from '@mui/material';
import CheckDoubleFillIcon from 'remixicon-react/CheckDoubleFillIcon';
import {
  ButtonWrapper,
  IconButton,
  IconWrapper,
  ModalBox,
  ModalHeadingBox,
  ModalTextBox,
} from './SuccessModal.css';
import { ISuccessModal } from './SuccessModal.types';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

export default function SuccessModal({
  title,
  text,
  primaryCta,
  secondaryCta,
  onClose,
}: ISuccessModal) {
  const theme = useTheme();

  return (
    <Modal open={true}>
      <ModalBox>
        <ModalHeadingBox>
          {onClose && (
            <IconButton
              id="success-modal.icon-button.close-modal"
              aria-label="close"
              onClick={onClose}
            >
              <CloseLineIcon size={24} color={theme.palette.neutral[500]} />
            </IconButton>
          )}
          <IconWrapper>
            <CheckDoubleFillIcon size={98} color={theme.palette.neutral[500]} />
            <CheckDoubleFillIcon
              size={98}
              color={theme.palette.secondary[500]}
            />
          </IconWrapper>

          <ModalTextBox>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="p3">{text}</Typography>
          </ModalTextBox>
        </ModalHeadingBox>

        <ButtonWrapper>
          {secondaryCta && (
            <Button
              buttonType="secondary"
              id="success-modal.button.secondary"
              size="large"
              onClick={secondaryCta.onClick}
            >
              {secondaryCta.text}
            </Button>
          )}

          {primaryCta && (
            <Button
              buttonType="primary"
              id="success-modal.button.primary"
              size="large"
              onClick={primaryCta.onClick}
            >
              {primaryCta.text}
            </Button>
          )}
        </ButtonWrapper>
      </ModalBox>
    </Modal>
  );
}
