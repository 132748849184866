import { styled } from '@frontend/theme';
import { Box as MUIBox } from '@mui/material';

/**
 * The flex and maxWidth pixels comes from:
 * {column_gap} * {number_of_gaps_per_row} / {number_of_cards_per_row}
 *
 * For 4 cards per row is: 12px * 3 spaces / 4 cards = 9px
 *
 * For 3 cards per row is: 12px * 2 spaces / 3 cards = 8px
 */
export const ListingImageWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: '1 1 calc(100%/4 - 9px)',
  maxWidth: 'calc(100%/4 - 9px)',
  width: '100%',
  boxSizing: 'border-box',
  cursor: 'pointer',
  [theme.breakpoints.down('tablet')]: {
    flex: '1 1 calc(100%/3 - 8px)',
    maxWidth: 'calc(100%/3 - 8px)',
  },
  '& > img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    aspectRatio: 1,
  },
}));
