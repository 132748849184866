import { RefObject, useEffect, useRef } from 'react';

export function useRequestCardScroll({
  enableScroll,
}: {
  enableScroll: boolean;
}) {
  const requestCardRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (enableScroll && requestCardRef.current) {
      setTimeout(() => {
        if (requestCardRef.current) {
          requestCardRef.current.scrollIntoView({
            behavior: 'instant',
            block: 'start',
            inline: 'nearest',
          });
        }
      }, 100);
    }
  }, [enableScroll]);

  return {
    requestCardRef,
  };
}
