import { useRef } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PLATFORM_ROUTES } from '@frontend/routing';
import { useMediaQuery, useTheme } from '@mui/material';
import { RequestListWrapper, RequestCardWrapper } from './RequestList.css';
import { IRequestsList } from './RequestList.types';
import { useGetDistanceToTop } from '../../../../app/shared/utils/useGetDistanceToTop/useGetDistanceToTop';
import RequestCard from './RequestCard/RequestCard';

export default function RequestList({ requests }: IRequestsList) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const { uuid } = useParams();
  const navigate = useNavigate();
  const refHTMLElement = useRef<HTMLDivElement>(null);
  const distanceToTop = useGetDistanceToTop({ elementRef: refHTMLElement });

  const handleClick = (clickedRequestUuid: string) => () => {
    const firstRequestDetailsPage = generatePath(
      PLATFORM_ROUTES.requestDetails,
      { uuid: clickedRequestUuid },
    );
    navigate(firstRequestDetailsPage, { replace: true });
  };

  return (
    <RequestListWrapper ref={refHTMLElement} distance={distanceToTop}>
      {requests.map(request => (
        <RequestCardWrapper key={request.uuid}>
          <RequestCard
            request={request}
            expanded={isDesktop ? request.uuid === uuid : true}
            onClick={handleClick(request.uuid)}
          />
        </RequestCardWrapper>
      ))}
    </RequestListWrapper>
  );
}
