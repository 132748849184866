import { AccordionSummary as MUIAccordionSummary } from '@mui/material';
import { styled } from '@frontend/theme';

export const MUIAccordionSummarySt = styled(MUIAccordionSummary)(() => ({
  '&.MuiAccordionSummary-root': {
    gap: '8px',
    padding: '4px 0',
    width: '100%',
    minHeight: 'auto',

    '&.Mui-expanded': {
      padding: 0,
      minHeight: 'auto',
    },

    '& > .MuiAccordionSummary-content': {
      margin: 0,
    },
  },
}));
