import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ApiContextProvider } from '@frontend/api';
import { AuthContextProvider, CheckExistingSession } from '@frontend/auth';
import { ThemeProvider } from '@frontend/theme';
import { TranslationProvider } from '@frontend/translation';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { init as sentryInit } from '@sentry/react';
import App from './app/app';
import sentryConfig from '../sentry.config';

const environment: string = import.meta.env.VITE_ENVIRONMENT || undefined;
if (environment === 'prod') {
  sentryInit(sentryConfig);
}

const gtmId: string = import.meta.env.VITE_GTM_TAG_ID || undefined;
if (gtmId && environment !== 'dev') {
  TagManager.initialize({ gtmId });
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <TranslationProvider>
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ApiContextProvider apiUrl={import.meta.env.VITE_METYCLE_API_URL}>
          <AuthContextProvider>
            <HelmetProvider>
              <BrowserRouter>
                <CheckExistingSession>
                  <App />
                </CheckExistingSession>
              </BrowserRouter>
            </HelmetProvider>
          </AuthContextProvider>
        </ApiContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </TranslationProvider>,
);
