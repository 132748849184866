import { styled } from '@frontend/theme';
import { Box as MUIBox, Container as MUIContainer } from '@mui/material';

export const PageContent = styled(MUIContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '40px',
}));

export const BannerWrapper = styled(MUIBox)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

export const ListingListWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  gap: '24px',
  rowGap: '40px',
  flexWrap: 'wrap',

  [theme.breakpoints.down('desktop')]: {
    gap: '12px',
    rowGap: '24px',
  },

  [theme.breakpoints.down('tablet')]: {
    gap: '12px',
  },
}));

export const ListingCardWrapper = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexBasis: 'calc((100% - 48px)/3)',

  [theme.breakpoints.down('desktop')]: {
    flexBasis: 'calc((100% - 12px)/2)',
  },

  [theme.breakpoints.down('tablet')]: {
    flexBasis: '100%',
  },
}));
