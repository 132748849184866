import { useGetListings } from '@frontend/api';
import { Translate } from '@frontend/translation';
import { FullPageLoader, PageWrapper } from '@frontend/ui-elements';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  BannerWrapper,
  ListingCardWrapper,
  ListingListWrapper,
  PageContent,
} from './SellerDashboard.css';
import CompanyStatusBanner from '../BuyerDashboard/BuyerDashboardHeader/CompanyStatusBanner/CompanyStatusBanner';
import ListingCard from './ListingCard/ListingCard';
import PlatformHeader from '../../app/shared/components/PlatformHeader/PlatformHeader';
import EmailVerificationBanner from '../../app/shared/components/EmailVerificationBanner/EmailVerificationBanner';
import { HEADER_VARIANTS } from '../../app/shared/components/PlatformHeader/PlatformHeader.types';

export default function SellerDashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const { status: getListingListStatus, data: listingListResponse } =
    useGetListings();

  if (getListingListStatus === 'pending') {
    return <FullPageLoader />;
  }

  if (getListingListStatus === 'success') {
    return (
      <>
        <PlatformHeader variant={HEADER_VARIANTS.SELLER} />
        <PageWrapper>
          <PageContent>
            <Typography variant={isMobile ? `h4` : 'h2'}>
              <Translate id="customer-platform.listing-list.heading" />
            </Typography>
            <BannerWrapper>
              <EmailVerificationBanner />
              <CompanyStatusBanner />
            </BannerWrapper>
            <ListingListWrapper>
              {listingListResponse?.data.map(listing => (
                <ListingCardWrapper key={listing.publicId}>
                  <ListingCard listing={listing} />
                </ListingCardWrapper>
              ))}
            </ListingListWrapper>
          </PageContent>
        </PageWrapper>
      </>
    );
  }
}
