import axiosClient from '../axiosClient';
import { useContext } from 'react';
import { ApiContext } from '../ApiContext/ApiContext';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IGetOrderListResponseFailure,
  TGetOrderListResponse,
} from './getOrderList.types';
import { getQueryKey } from './getOrderList.util';

const submitGetOrderList = (apiUrl: string, requestId: string) => {
  return axiosClient.get(`${apiUrl}/orders?request-uuid=${requestId}`);
};

const useGetOrderListQuery = (requestId: string) => {
  const { apiUrl } = useContext(ApiContext);
  return useQuery<
    AxiosResponse<TGetOrderListResponse>,
    AxiosError<IGetOrderListResponseFailure>,
    AxiosResponse<TGetOrderListResponse>
  >({
    queryFn: () => submitGetOrderList(apiUrl, requestId),
    queryKey: getQueryKey(requestId),
  });
};

export default useGetOrderListQuery;
