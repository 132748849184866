import { forwardRef, Ref, useRef, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { DatePicker as MUIDatePicker } from './DatePicker.css';
import DatePickerProps from './DatePicker.types';
import ArrowDropDownLineIcon from 'remixicon-react/ArrowDropDownLineIcon';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import CalendarLineIcon from 'remixicon-react/CalendarLineIcon';
import DatePickerActionList from './DatePickerActionList/DatePickerActionList';
import DatePickerLayout from './DatePickerLayout/DatePickerLayout';
import DatePickerToolbar from './DatePickerToolbar/DatePickerToolbar';
import { format, isDate } from 'date-fns';
import { FormFieldAction, trackFormFieldEvent } from '@frontend/tracking';
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';

/**
 * This component has a unique behavior and interaction that require dynamic client-side rendering.
 * It can't be added to the lib/ui-elements library because it will break the server-side rendering (SSR)
 * By keeping this components in customer-platform, we ensure that it is handled independently.
 */
const DatePicker = forwardRef(function (
  props: DatePickerProps,
  ref?: Ref<HTMLDivElement>,
) {
  const {
    error = false,
    helperText,
    id,
    required = false,
    open: defaultOpen,
    formId,
    value,
    onChange,
    ...rest
  } = props;

  const theme = useTheme();
  const isTabletLOrLess = useMediaQuery(theme.breakpoints.down('tabletL'));
  const [open, setOpen] = useState(defaultOpen);
  const initialValue = useRef<string | null | undefined>(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const trackFocusEvent = (
    action: FormFieldAction,
    oldValue?: string | null,
    newValue?: string | null,
  ) => {
    trackFormFieldEvent({
      formId: formId ?? 'unknown-form',
      formField: props.id ?? 'unknown-field',
      formFieldAction: action,
      formFieldOldValue: oldValue,
      formFieldNewValue: newValue,
    });
  };

  const handleOpen = () => {
    if (inputRef.current) {
      trackFocusEvent('focus');
    }
  };

  const handleClose = () => {
    if (inputRef.current) {
      if (initialValue.current !== inputRef.current.value) {
        trackFocusEvent('update', inputRef.current.value, initialValue.current); // When get value from input is the old one
      }
      trackFocusEvent('unfocus');
      setOpen(false);
    }
  };

  const handleChange = (
    value: unknown,
    context: PickerChangeHandlerContext<DateValidationError>,
  ) => {
    if (inputRef.current) {
      initialValue.current = format(value as Date, 'dd/MM/yyyy');
      if (onChange) onChange(value as Date, context);
    }
  };

  return (
    <MUIDatePicker
      {...rest}
      inputRef={inputRef}
      ref={ref}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleChange}
      autoFocus={false}
      value={isDate(value) ? value : null}
      slots={{
        openPickerIcon: CalendarLineIcon,
        switchViewIcon: ArrowDropDownLineIcon,
        leftArrowIcon: ArrowLeftSLineIcon,
        rightArrowIcon: ArrowRightSLineIcon,
        toolbar: DatePickerToolbar,
        layout: DatePickerLayout,
        actionBar: DatePickerActionList,
      }}
      slotProps={{
        actionBar: {
          actions: isTabletLOrLess ? ['accept', 'cancel'] : [],
        },
        textField: {
          id: id,
          error: error,
          helperText: helperText,
          required: required,
          onClick: () => setOpen(true),
        },
        toolbar: {
          hidden: !isTabletLOrLess,
        },
      }}
    />
  );
});

export default DatePicker;
